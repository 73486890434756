import React from "react";
import ContactButton from "../../components/contactButton";
import HeaderDivideTextLeft from "../../components/headerDivideTextLeft";
import Layout from "../../components/layout";
import Row from "starkeydigital/React/Components/row";
import Accordion from "starkeydigital/React/Components/accordion";
import AnimateUp from "starkeydigital/React/Components/AnimateUp";
interface props {}
const SeniorLeaders: React.FC<props> = (props) => {
  return (
    <Layout
      titleText="Senior Leaders"
      titleText2=""
      slogan="We have been designing and delivering leadership programmes for many years."
    >
      <HeaderDivideTextLeft
        style={{marginTop: "115px"}}
        column="col-md-10"
        header="Senior Leaders"
        text="We adopt a distinctive approach which focuses on the client and their needs, but brings to this their experience of what will work most effectively in the client’s environment, seeking always to build around the areas which will deliver value to the business. We do not work with theoretical case studies, we work with the issues you are facing. This delivers real time learning and value for the individual and the organisation."
      />

      <Row>
        <div className="col mt-4">
          <ContactButton>Ready to work together?</ContactButton>
        </div>
      </Row>

      <Row style={{marginTop: "60px"}}>
        <div className="col">
          <Accordion>
            <AnimateUp>
              <Accordion.Item title="Why would you invest in Leadership Programmes?">
                Your talent pipeline is not delivering the leadership expertise
                your organisation needs for your future plans / You have
                identified a development need for a group of your employees /
                High fliers in your organisation need additional skills or
                knowledge to fulfil their potential / You are suffering from a
                significant loss of executive talent / You are going through
                change in your organisation / A group of employees need to
                acquire new knowledge, skills or behaviours
              </Accordion.Item>
            </AnimateUp>
            <AnimateUp>
              <Accordion.Item title="How would Leadership Programmes work?">
                Initial consultation to help understand the most effective way
                to meet your needs and deliver value to the business / Working
                with you and your organisation’s culture to design a programme
                that enhances your leadership training / Delivering (or bringing
                a combined team together to deliver) the programme that meets
                your needs
              </Accordion.Item>
            </AnimateUp>

            <AnimateUp>
              <Accordion.Item title="Examples of work done">
                Leadership Development / Understanding organisational politics /
                Taking control of your career/ Developing client relationships /
                High fliers programmes / How to understand and lead your team to
                success
              </Accordion.Item>
            </AnimateUp>
          </Accordion>
        </div>
      </Row>
    </Layout>
  );
};
export default SeniorLeaders;
